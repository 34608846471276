<template>
  <div class="template-orders-list">
    <div class="col-12 pt-4">
      <b-overlay id="overlay-background" :show="$apollo.loading" rounded="sm">
        <h2>Orders</h2>
        <div class="card">
          <div class="card-body">
            <div class="row mb-sm-2">
              <div class="col-sm-4 mb-3">
                <b-form-datepicker
                  id="startDate"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  placeholder="Choose start date"
                  local="en"
                  v-model="startDate"
                ></b-form-datepicker>
              </div>
              <div class="col-sm-4 mb-3">
                <b-form-datepicker
                  id="endDate"
                  :date-format-options="{
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  }"
                  placeholder="Choose end date"
                  local="en"
                  v-model="endDate"
                ></b-form-datepicker>
              </div>
              <div class="col-sm-4 pb-btn-group">
                <div class="col-3">
                  <b-button
                    variant="light"
                    :disabled="isGetOrdersBtnDisable"
                    size="sm"
                    @click="getOrders()"
                    ><i class="uil uil-check font-18 text-success"></i
                  ></b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <order-listing :orders="ordersTable.orders" :wcZones="wcZoneTable" />
        </div>
      </b-overlay>
    </div>
  </div>
  <!-- template-orders-list -->
</template>

<script>
import _ from "lodash";
import orderListing from "@/components/subcomponents/orders/orderListing.vue";
import { GET_ORDERS, GET_WC_ZONES } from "@/graphql/queries/queries";
import { bus } from "@/main";

const ORDERS_ATTRIBUTE = "fetchOrders";
const WC_ZONES_ATTRIBUTE = "zone_wc_products";

export default {
  name: "orders",

  components: {
    orderListing,
  },

  data() {
    return {
      ordersTable: {},
      wcZoneTable: [],
      selectedOrdersId: [],
      selectedOrdersCSV: [],
      startDate: null,
      endDate: null,
    };
  },
  computed: {
    isGetOrdersBtnDisable() {
      return this.startDate === null || this.endDate === null ? true : false;
    },
  },
  mounted() {
    bus.$on("reloadOrder", (val) => {
      if (val) {
        this.getOrders();
      }
    });
  },
  methods: {
    getOrders() {
      this.$apollo.queries.ordersTable.skip = false;
      this.$apollo.queries.ordersTable.refetch();
    },
    //filters Orders according to order's bookingStatus
    getSelectedOrders(orders) {
      this.selectedOrdersId = orders;
      const paidCompletedOrders = _.filter(this.ordersTable.orders, (o) => {
        return o.bookingStatus === "complete" || o.bookingStatus === "paid";
      });
      this.selectedOrdersCSV = _.map(paidCompletedOrders, (order) => {
        if (_.includes(this.selectedOrdersId, order.orderId)) {
          return order;
        }
      });
      _.remove(this.selectedOrdersCSV, (o) => o === undefined);
    },
  },
  apollo: {
    ordersTable: {
      // graphql query
      query: GET_ORDERS,
      variables() {
        return {
          from: this.startDate,
          to: this.endDate,
        };
      },
      update(data) {
        this.$apollo.queries.ordersTable.skip = true;
        return data[ORDERS_ATTRIBUTE];
      },
      fetchPolicy: "no-cache",
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
        this.ordersTable = {};
      },
      skip: true,
    },
    wcZoneTable: {
      query: GET_WC_ZONES,
      update(data) {
        return data[WC_ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../css/sass_files";

.template-orders-list {
  padding-top: 0;
  margin: 0;

  h2 {
    text-transform: uppercase;
    font-size: 20px;
  }

  .col-12 {
    padding: 0;
  }

  .pb-btn-group {
    padding: 0;
    display: flex;
  }
}
</style>
