import gql from "graphql-tag";

//Add Zone
export const ADD_ZONE = gql`
  mutation addZone($input: zone_insert_input!) {
    insert_zone_one(object: $input) {
      id
    }
  }
`;

// Add one operator
export const ADD_SINGLE_OPERATOR = gql`
  mutation addOperator($input: operators_insert_input!) {
    insert_operators_one(object: $input) {
      operator_id
    }
  }
`;

// Update operator
export const UPDATE_SINGLE_OPERATOR = gql`
  mutation updateOperator(
    $pk: uuid!
    $operator: operators_set_input!
    $user: users_set_input!
  ) {
    update_operators_by_pk(pk_columns: { operator_id: $pk }, _set: $operator) {
      operator_id
    }

    update_users_by_pk(pk_columns: { id: $pk }, _set: $user) {
      id
    }
  }
`;

// Add Pilot
export const ADD_PILOTS = gql`
  mutation insertPilot($input: pilots_insert_input!) {
    insert_pilots_one(object: $input) {
      pilot_id
      user {
        name
        email
      }
    }
  }
`;

// Update Pilot
export const UPDATE_PILOT_ONE = gql`
  mutation updatePilot(
    $pilotId: uuid!
    $license_id: Int!
    $bank_details_id: Int!
    $pilotData: pilots_set_input!
    $user: users_set_input!
    $pilot_license: pilot_license_set_input!
    $bank_account: bank_account_set_input!
  ) {
    update_pilots(where: { pilot_id: { _eq: $pilotId } }, _set: $pilotData) {
      affected_rows
      returning {
        pilot_id
      }
    }
    update_users_by_pk(pk_columns: { id: $pilotId }, _set: $user) {
      id
    }
    update_pilot_license_by_pk(
      pk_columns: { id: $license_id }
      _set: $pilot_license
    ) {
      license_no
    }
    update_bank_account_by_pk(
      pk_columns: { id: $bank_details_id }
      _set: $bank_account
    ) {
      account_no
    }
  }
`;

// UPSERT PILOT INSURANCE TABLE
export const UPSERT_PILOT_INSURANCE = gql`
  mutation addOrUpdateInsurance($data: pilot_insurance_insert_input!) {
    insert_pilot_insurance_one(
      object: $data
      on_conflict: {
        constraint: pilot_insurance_pkey
        update_columns: [expiry_date, insurance_provider, issue_date, policy_no]
      }
    ) {
      id
    }
  }
`;

// ADD USER
export const ADD_USER = gql`
  mutation addUser($input: users_insert_input!) {
    insert_users_one(
      object: $input
      on_conflict: {
        constraint: users_email_key
        update_columns: [name, phone_no, alternate_phone_no]
      }
    ) {
      id
      user_roles {
        role
      }
    }
  }
`;

// ADD USER ROLE
export const ADD_USER_ROLE = gql`
  mutation addUserRole($input: user_roles_insert_input!) {
    insert_user_roles_one(object: $input) {
      role
    }
  }
`;

// DELETE RESERVE
export const DELETE_RESERVE = gql`
  mutation deleteReserve($pk: Int!) {
    delete_reserves_by_pk(id: $pk) {
      id
    }
  }
`;

// ADD RESERVE
export const ADD_RESERVE = gql`
  mutation insertReserve($input: reserves_insert_input!) {
    insert_reserves_one(object: $input) {
      id
    }
  }
`;

// UPDATE RESERVE
export const UPDATE_RESERVE_PK = gql`
  mutation updateReserve($id: Int!, $input: reserves_set_input!) {
    update_reserves_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;

// ADD WING
export const ADD_WING = gql`
  mutation InsertWing($input: wings_insert_input!) {
    insert_wings_one(object: $input) {
      id
    }
  }
`;

// UPDATE WING

export const UPDATE_WING = gql`
  mutation updateWing($input: wings_set_input!, $id: Int!) {
    update_wings_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;

// DELETE WING
export const DELETE_WING = gql`
  mutation deleteWing($pk: Int!) {
    delete_wings_by_pk(id: $pk) {
      id
    }
  }
`;

// ADD BRAND
export const ADD_EQUIPMENT_BRAND = gql`
  mutation InsertBrand($input: equipment_brands_insert_input!) {
    insert_equipment_brands_one(object: $input) {
      value
    }
  }
`;

// ADD MODEL
export const ADD_EQUIPMENT_MODEL = gql`
  mutation InsertModels($input: equipment_models_insert_input!) {
    insert_equipment_models_one(object: $input) {
      name
    }
  }
`;

// ADD WC_ORDER
export const ADD_WC_ORDER = gql`
  mutation addWcOrder(
    $data: [flights_insert_input!]!
    $order_data: jsonb!
    $order_id: Int!
    $product_id: Int!
  ) {
    insert_wc_orders(
      objects: {
        flights: { data: $data }
        order_data: $order_data
        order_id: $order_id
        product_id: $product_id
      }
    ) {
      affected_rows
      returning {
        order_id
      }
    }
  }
`;

//DELETE WC_ORDER
export const DELETE_WC_ORDER = gql`
  mutation delete_wc_order($input: Int!) {
    delete_wc_orders_by_pk(order_id: $input) {
      order_id
    }
  }
`;
