<template>
  <div>
    <b-form-checkbox
      v-model="deletePassenger"
      :disabled="isDisabled"
      @input="changeState"
    >
    </b-form-checkbox>
  </div>
</template>
<script>
import { bus } from "@/main";
export default {
  props: {
    order: {
      type: Object,
    },
    flightData: {
      type: Array,
    },
    index: {
      type: Number,
    },
    wcOrders: {
      type: Array,
    },
  },
  data() {
    return {
      deletePassenger: false,
      isDisabled: false,
      editVal: false,
    };
  },
  mounted() {
    bus.$on("checkStateChange", (val) => {
      this.editVal = val;
    });
  },
  watch: {
    flightData() {
      if (this.wcOrders.length > 0) {
        let o = this.wcOrders[0].order_data;
        if (this.flightData.length > 0) {
          if (o.paxData.length === o.quantity) {
            this.isDisabled = false;
          } else if (this.order.bookingStatus === "cancelled") {
            this.isDisabled = false;
          } else {
            this.isDisabled = !this.editVal;
          }
        } else {
          this.isDisabled = true;
        }
      }
    },
  },
  methods: {
    changeState() {
      this.$emit("input", this.deletePassenger);
    },
  },
};
</script>
