var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-orders-list"},[_c('div',{staticClass:"col-12 pt-4"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.$apollo.loading,"rounded":"sm"}},[_c('h2',[_vm._v("Orders")]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-sm-2"},[_c('div',{staticClass:"col-sm-4 mb-3"},[_c('b-form-datepicker',{attrs:{"id":"startDate","date-format-options":{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                },"placeholder":"Choose start date","local":"en"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('div',{staticClass:"col-sm-4 mb-3"},[_c('b-form-datepicker',{attrs:{"id":"endDate","date-format-options":{
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                },"placeholder":"Choose end date","local":"en"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('div',{staticClass:"col-sm-4 pb-btn-group"},[_c('div',{staticClass:"col-3"},[_c('b-button',{attrs:{"variant":"light","disabled":_vm.isGetOrdersBtnDisable,"size":"sm"},on:{"click":function($event){return _vm.getOrders()}}},[_c('i',{staticClass:"uil uil-check font-18 text-success"})])],1)])])])]),_c('div',[_c('order-listing',{attrs:{"orders":_vm.ordersTable.orders,"wcZones":_vm.wcZoneTable}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }