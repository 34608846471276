<template>
  <div>
    <div class="col-12 no-padding">
      <div class="card">
        <div class="card-body">
          <h6>Filters</h6>
          <div class="row">
            <div class="col-12 col-sm-5 mb-3">
              <multiselect
                v-model="bookingStatus"
                :multiple="true"
                :disabled="dropDownDisable"
                :options="bookingsOptions"
                placeholder="Filter by Booking Status"
              />
            </div>
            <div class="col-12 col-sm-5 mb-3">
              <multiselect
                v-model="flightType"
                :disabled="dropDownDisable"
                :multiple="true"
                :options="flightTypeOptions"
                placeholder="Filter by Flight Type"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="header-title mt-2">Total Orders : {{ totalRows }}</h4>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              ref="table"
              show-empty
              striped
              class="orders-list"
              :items="ordersTable"
              :fields="fields"
              :no-border-collapse="true"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              select-mode="multi"
            >
              <!-- Order ID & Channel-->
              <template v-slot:cell(orderId)="row">
                &#x23;{{ row.item.orderId }}
                <small class="order-channel">Website</small>
              </template>

              <!-- Flight Type -->
              <template v-slot:cell(flightType)="row">
                {{ row.item.flightType }}
                <small class="ops-zone">{{ zoneName(row.item) }}</small>
                <!-- Insert Zone here -->
              </template>

              <!-- Date & Time -->
              <template v-slot:cell(startTime)="row">
                {{ formatDate(row.item.startTime) }}
                <small class="start-time">{{
                  formatTime(row.item.startTime)
                }}</small>
              </template>

              <!-- Customer Name + Phone -->
              <template v-slot:cell(customerName)="row">
                {{ row.item.customerName }}
                <small class="customer-phone"
                  ><i class="uil uil-phone font-12 text-success mr-1"></i
                  >{{ row.item.customerPhone }}</small
                >
              </template>

              <!-- Item Quantity & Pickup Location -->
              <template v-slot:cell(quantity)="row">
                x{{ row.item.quantity }}
                <small class="pickup-loc">{{ row.item.pickupLocation }}</small>
                <!--Limit Pickup location to first 2 words-->
              </template>

              <!-- Assignment Status -->
              <template v-slot:cell(changeStatus)="row">
                <!-- <span
                  class="badge mr-2"
                  :class="{
                    'badge-primary': row.item.assignmentStatus === 'Assigned',
                    'badge-warning': row.item.assignmentStatus === 'Pending',
                    'badge-danger': row.item.assignmentStatus === 'Review'
                  }"
                  >helo</span> -->
                <span v-if="row.item.changeStatus.assigned">
                  <span
                    v-if="
                      row.item.changeStatus.partialCancellation ||
                      row.item.changeStatus.rescheduled ||
                      row.item.changeStatus.fullCancellation ||
                      row.item.changeStatus.downGraded
                    "
                    class="badge mr-2 badge-danger"
                  >
                    Review
                  </span>
                  <span class="badge mr-2 badge-primary" v-else>
                    Assigned
                  </span>
                </span>
                <span
                  v-else-if="!row.item.changeStatus.assigned"
                  class="badge mr-2 badge-warning"
                >
                  Pending
                </span>
                <span
                  v-else-if="
                    row.item.changeStatus.partialCancellation ||
                    row.item.changeStatus.rescheduled
                  "
                  class="badge mr-2 badge-danger"
                >
                  Review
                </span>
              </template>

              <!-- Payment Information + Razorpay ID -->
              <template v-slot:cell(totalPayment)="row">
                <span
                  class="badge mr-2"
                  :class="{
                    'badge-success': row.item.bookingStatus === 'paid',
                    'badge-info': row.item.bookingStatus === 'complete',
                    'badge-danger': row.item.bookingStatus === 'cancelled',
                    'badge-light': row.item.bookingStatus === 'unpaid',
                    'badge-danger-lighten':
                      row.item.bookingStatus === 'Payment Failed',
                  }"
                  >{{ row.item.bookingStatus }}</span
                >
                <small
                  class="total-payment"
                  :class="{
                    hide:
                      row.item.totalPayment ===
                      row.item.totalPayment + row.item.refunds,
                  }"
                  >₹{{ parseInt(row.item.totalPayment) }}</small
                >
                <span class="order-payment"
                  >₹{{
                    parseInt(row.item.totalPayment) + refunds(row.item.refunds)
                  }}</span
                >
              </template>

              <!-- Action buttons: View/Assign -->
              <template v-slot:cell(actions)="row">
                <a
                  @click="
                    openOrderDetailModal(row.item, row.index, $event.target)
                  "
                  title="View"
                  class="action-icon view"
                >
                  <i class="mdi mdi-eye"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <!-- # of entries per page selector -->
            <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="ml-2 mr-2"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="dataTables_paginate paging_simple_numbers">
                <!-- pagination -->
                <b-pagination
                  class="justify-content-center justify-content-sm-end"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Order Detail Modal -->
    <order-detail-modal
      ref="orderDetailModalRef"
      :orderModalId="viewOrderModalId"
      :order="selectedOrder"
      :product="selectedProduct"
      :zoneFlightTypes="zoneFlightTypes"
    />
  </div>
</template>

<script>
import orderDetailModal from "@/components/subcomponents/orders/orderDetailModal.vue";
import { DateTime } from "luxon";
import Multiselect from "vue-multiselect";
export default {
  name: "orderListing",
  props: {
    orders: {
      type: Array,
      default: null,
    },
    wcZones: {
      type: Array,
    },
  },
  components: {
    orderDetailModal,
    Multiselect,
  },
  data() {
    return {
      ordersTable: [],
      bookingsOptions: ["paid", "unpaid", "complete", "cancelled"],
      flightTypeOptions: ["Prime", "Classic", "XC"],
      bookingStatus: [],
      flightType: [],
      dropDownDisable: true,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "orderId",
          label: "Order",
          class: "order-id",
          stickyColumn: true,
          sortable: true,
        },
        { key: "flightType", label: "Flight", class: "flight-type" },
        {
          key: "startTime",
          label: "Date/Time",
          class: "date-time",
          sortable: true,
        },
        { key: "customerName", label: "Customer", class: "customer-name" },
        { key: "quantity", label: "Pax", class: "pax-qty", sortable: true },
        {
          key: "changeStatus",
          label: "Assign",
          class: "ol-assignment-status",
        },
        { key: "totalPayment", label: "Payment", class: "payment-info" },
        { key: "actions", label: "", class: "action-links" },
      ],

      // Modal for Order Details
      viewOrderModalId: "view-order-detail",
      selectedOrder: null,
      selectedProduct: null,
      zoneFlightTypes: null,
    };
  },
  computed: {
    rows() {
      return this.orders !== null ? this.orders.length : 0;
    },
  },
  watch: {
    orders() {
      this.bookingStatus = [];
      this.flightType = [];
      this.filterOrders();
      this.dropDownDisable = false;
      this.ordersTable = this.orders;
      this.totalRows = this.ordersTable.length;
    },
    bookingStatus() {
      this.filterOrders();
    },
    flightType() {
      this.filterOrders();
    },
  },
  mounted() {
    this.ordersTable = this.orders;
  },
  methods: {
    filterOrders() {
      this.ordersTable = this.orders;
      if (this.bookingStatus.length > 0 && this.flightType.length > 0) {
        this.ordersTable = this.ordersTable.filter(
          (a) =>
            this.bookingStatus.includes(a.bookingStatus) &&
            this.flightType.includes(a.flightType.split(" ")[0])
        );
      } else if (this.bookingStatus.length > 0) {
        this.ordersTable = this.ordersTable.filter((a) =>
          this.bookingStatus.includes(a.bookingStatus)
        );
      } else if (this.flightType.length > 0) {
        this.ordersTable = this.ordersTable.filter((a) =>
          this.flightType.includes(a.flightType.split(" ")[0])
        );
      }
      this.totalRows = this.ordersTable.length;
    },
    getProduct(item) {
      let selectedProducts = this.wcZones.filter(
        (a) => a.wc_product_id === item.productId
      );
      return selectedProducts[0];
    },
    zoneName(item) {
      return this.getProduct(item).zone.name;
    },
    getZoneFlightTypes(item) {
      const product = this.getProduct(item);
      const selectedProducts = this.wcZones.filter(
        (a) => product.zone.id === a.zone.id
      );
      return selectedProducts.map((p) => p.type);
    },
    formatDate(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATE_FULL);
    },
    formatTime(date) {
      // HACK: wp rest api timezone data is misleading and not adjusted for timezone.
      return DateTime.fromISO(date).toUTC().toFormat("h:mm a");
    },
    openOrderDetailModal(rowItem, rowIndex, eventTarget) {
      this.selectedOrder = rowItem;
      this.selectedProduct = this.getProduct(rowItem);
      this.zoneFlightTypes = this.getZoneFlightTypes(rowItem);
      this.$root.$emit("bv::show::modal", this.viewOrderModalId, eventTarget);
    },
    refunds(order) {
      return (
        order.reduce(
          (total, val) => parseInt(total) + parseInt(val.total),
          0
        ) || 0
      );
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
::v-deep {
  .no-padding {
    padding: 0;
  }

  table.table {
    color: #6c757d;

    .order-id {
      font-weight: bold;
    }

    .flight-type {
      min-width: 235px;
    }

    .ops-zone,
    .pickup-loc,
    .order-channel {
      display: block;
    }

    .date-time {
      min-width: 150px;
    }

    .customer-name {
      font-weight: bold;
      min-width: 150px;
    }

    .customer-phone {
      display: block;
    }

    .pax-qty {
      font-weight: bold;
    }

    .order-payment {
      font-weight: bold;
      display: block;
    }

    .total-payment {
      font-weight: bold;
      text-decoration: line-through;
    }

    .total-payment.hide {
      display: none;
    }

    .razorpay-id {
      display: block;
    }

    .start-time {
      font-weight: bold;
      display: block;
    }

    .action-icon {
      display: inherit;
      padding: 0 5px;
      font-size: 1.2rem;
      color: #98a6ad;
      cursor: pointer;
    }
    .checkbox {
      padding: 10px;
    }
    .action-icon:hover {
      color: #6c757d;
    }
  }
}
</style>
