<template>
  <div v-if="displayMode">
    <b-button
      class="btn-edit"
      :disabled="disabled"
      title="Edit Assignment"
      size="sm"
      variant="light"
      @click="changeState()"
      ><i class="uil uil-edit font-18 text-secondary"></i
    ></b-button>
  </div>
</template>
<script>
import { bus } from "@/main";
export default {
  props: {
    index: {
      type: Number,
    },
    flightDate: {
      type: Array,
    },
    wcOrders: {
      type: Array,
    },
  },
  data() {
    return {
      isSelect: false,
      displayMode: false,
      disabled: false,
    };
  },
  watch: {
    flightDate() {
      if (this.wcOrders.length > 0) {
        if (this.flightDate.length === 0) {
          if (this.wcOrders[0].order_data.bookingStatus === "cancelled") {
            this.displayMode = false;
          } else {
            this.displayMode = true;
          }
        } else {
          this.displayMode = false;
        }
      } else {
        this.displayMode = false;
      }
    },
    index() {
      this.disabled = true;
    },
  },
  methods: {
    changeState() {
      this.isSelect = !this.isSelect;
      bus.$emit("checkStateChange", this.isSelect, this.index);
    },
  },
};
</script>
<style scoped>
.btn-edit {
  background: none;
  border: none;
}
</style>
